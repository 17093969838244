.statistics-container {
  padding: 20px;
  margin: 20px 0;
}

.niveau-section {
  margin-bottom: 30px;
}

.niveau-title {
  font-size: 20px;
  margin-bottom: 10px;
  color: #1976d2;
}

.niveau-table {
  width: 100%;
  border-collapse: collapse;
}

.niveau-table th,
.niveau-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.niveau-table th {
  background-color: #1976d2;
  color: white;
}

.niveau-table tr:nth-child(even) {
  background-color: #f2f2f2;
}
