/* Conteneur principal pour le filtre */
.filter-container {
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  gap: 15px; /* Ajout d'espace entre les éléments */
}

/* Titre du filtre */
.filter-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
  text-align: center;
}

/* Style pour les sélecteurs */
.select-filter {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  background-color: #ffffff;
  color: #555;
  width: 100%;
  max-width: 300px;
  margin: 0 auto; /* Centrer les sélecteurs si nécessaire */
}

/* Style du bouton d'impression */
button {
  padding: 10px 20px;
  font-size: 14px;
  color: white;
  background-color: #1976d2;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  align-self: center; /* Centrer le bouton */
}

button:hover {
  background-color: #145a9e;
}

/* Conteneur pour les statistiques */
.statistic-container {
  padding: 20px;
  margin-top: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f8f8f8;
}

/* Titre des statistiques */
.statistic-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #555;
  text-align: center;
}
